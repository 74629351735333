import React, { Component } from 'react'
import {Button, Container, Form, Icon, Input, Loader} from "semantic-ui-react";
import {Editor} from "@toast-ui/react-editor";
import axios from 'axios';
import { S3UploadFile} from "../../utils/s3-file-upload";
import FileUploadButton from "../../component/file_upload_button";
import UserContext from "../../UserContext";
import { process_editor_images } from '../../utils/file_processing'


export default class ItemEditPage extends Component {
  state = { tags: [], categoryOptions: [], isLoading: true}
  editorRef = React.createRef();
  static contextType = UserContext;

  constructor (props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
  }

  async componentDidMount () {
    try {
      const id = this.query.get('id')

      const categoryList = (await axios.get(`${process.env.REACT_APP_API_URL}/market/category`)).data;

      for (const cat of categoryList) {
       this.state.categoryOptions.push({
         key: cat.uuid,
         text: `${cat.emoji}ㅤ${cat.name}`,
         value: cat.uuid,
       })
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/market/item/${id}`)

      const res = response.data
      
      this.setState({
        isLoading: false,
        title: res.title,
        subtitle: res.subtitle,
        icon_url: res.icon_url,
        preview_url: res.preview_url,
        category: res.category.uuid,
        description: res.description,
        description_original: res.description,
        price: res.price,
        original_price: res.original_price,
        group: res.group,
        tags: res.tags.map((tag) => tag.name),
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleUpdate = async () => {
    const editorInstance = this.editorRef.current.getInstance();
    this.setState({
      'description': await editorInstance.getMarkdown()
    })

    try {
      if (this.state.icon_url_file) {
        const { url } = await S3UploadFile(this.state.icon_url_file, "market/item/icon");
        this.setState({
          'icon_url': url
        })
      }
      if (this.state.preview_url_file) {
        const { url } = await S3UploadFile(this.state.preview_url_file, "market/item/preview");
        this.setState({
          'preview_url': url
        })
      }

      // processing description_images
      await process_editor_images(this.state.description, this.state.description_original);

      await axios.put(`${process.env.REACT_APP_API_URL}/market/item/${this.query.get('id')}`,
        {
          title: this.state.title,
          subtitle: this.state.subtitle,
          category: this.state.category,
          description: this.state.description,
          price: this.state.price,
          original_price: this.state.original_price,
          tags: this.state.tags,
          icon_url: this.state.icon_url,
          preview_url: this.state.preview_url,
        }, {withCredentials: true});
      alert('상품 정보를 업데이트 했습니다!');
      window.location.href = "/market"
    } catch (e) {
      console.log(e);
      alert("상품 정보 업데이트에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/market/item/${this.query.get('id')}`, {withCredentials: true});
      alert('상품을 삭제했습니다!')
      window.location.href = '/market'
    } catch (e) {
      console.log(e);
      alert('상품 삭제에 실패했습니다.')
    }
  }

  handleFileUpload = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let file = event.target.files[0];
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + "_file"]: file,
          [event.target.name]: fileReader.result
        });
      }
      fileReader.readAsDataURL(file);
    }
  }

  render () {
    const user = this.context;
    const defaultTags = [];

    const tagsLabel = (tags) => {
      const tag = [];
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t
        })
      }
      return tag.concat(defaultTags);
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='youtube' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>상품 올리기</h2>
            <p className='color-gray'>마켓에 내 상품을 올립니다.</p>
          </div>
        </div>
        {
          (!this.state.isLoading) ?
            <Form>
              <p className='fw-b'>제목</p>
              <Form.Field
                control={Input}
                fluid
                className='mb-7'
                value = {`${this.state.title}`}
                name='title'
                onChange={this.handleChange}
              />

              <p className='fw-b'>부제목</p>
              <Form.Field
                control={Input}
                fluid
                className='mb-7'
                value = {`${this.state.subtitle}`}
                name='subtitle'
                onChange={this.handleChange}
              />


              <p className='fw-b'>카테고리</p>
              <Form.Dropdown
                placeholder={'카테고리를 선택해주세요.'}
                fluid
                selection
                options={this.state.categoryOptions}
                className='mb-7'
                value={this.state.category}
                name='category'
                onChange={this.handleChange}
              />

              <div style={{display: 'flex', flexWrap: 'wrap'}} >
                <div style={{flex: '1 1 40%'}} className='mr-5'>
                  <p className='fw-b'>할인 전 가격</p>
                  <Form.Field
                    control={Input}
                    fluid
                    className='mb-7'
                    value = {`${this.state.original_price}`}
                    name='original_price'
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{flex: '1 1 40%'}}>
                  <p className='fw-b'>가격</p>
                  <Form.Field
                    control={Input}
                    fluid
                    className='mb-7'
                    value = {`${this.state.price}`}
                    name='price'
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <p className='fw-b'>아이콘</p>
              <div className='mb-5'>
                <img
                  style={{width: '120px', height: '120px', borderRadius: '30%', border: '1px solid #eaeaea', objectFit: 'cover',}}
                  src={this.state.icon_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"} alt="icon"
                />
              </div>
              <FileUploadButton
                className='mt-5 mb-7'
                label="아이콘 업로드"
                accept="image/*"
                name="icon_url"
                handleChange={this.handleFileUpload}
              />

              <p className='fw-b'>미리보기</p>
              <div style={{maxWidth: '300px'}} className='mb-5'>
                <div className='p-box-16by9'>
                  <div className='p-box-ch'>
                    <img src={this.state.preview_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'} alt="preview"
                         style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px'}}/>
                  </div>
                </div>
              </div>
              <FileUploadButton
                className='mt-5 mb-7'
                label="미리보기 업로드"
                accept="image/*"
                name="preview_url"
                handleChange={this.handleFileUpload}
              />

              <p className='fw-b'>상품 설명</p>
              <Editor
                previewStyle="tab"
                height={'700px'}
                ref={this.editorRef}
                initialValue={this.state.description}
                hideModeSwitch={true}
                hooks={{
                  addImageBlobHook: async (blob, callback) => {
                    const { url } = await S3UploadFile(blob, `market/item/description/${user.group.uuid}`);
                    callback(url, blob.name); // callback(src, alt);
                    return false;
                  }
                }}
              />

              <p className='fw-b mt-7'>검색 태그</p>
              <Form.Dropdown
                placeholder='검색 태그를 등록해주세요.'
                fluid
                search
                selection
                multiple
                allowAdditions
                onAddItem={(event, data) => defaultTags.push({key: data.value, text: data.value, value: data.value})}
                options={tagsLabel(this.state.tags)}
                renderLabel={renderLabel}
                value={this.state.tags}
                name='tags'
                onChange={this.handleChange}
              />

              <Button onClick={this.handleUpdate} className='mv-7 mr-5'>수정</Button>
              <Button onClick={this.handleDelete} negative className='mv-7'>삭제</Button>
            </Form>
            : <Loader active inline='centered'/>
        }
      </Container>
    )
  }
}
