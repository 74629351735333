import React, {Component} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import moment from "moment";
import {Link} from "react-router-dom";

export default class YoutubeCard extends Component {
  render() {
    return (
      <div className='p-card'>
        <div className='p-box-16by9'>
          <img alt='YouTube Thumbnail' className='p-box-ch' style={{objectFit: 'cover'}}
               src={'https://img.youtube.com/vi/' + this.props.videoInfo.youtube_id + '/0.jpg'}/>
        </div>
        <div className='pd-1'>
          <p className='fw-b color-black'>{this.props.videoInfo.title}</p>
          <p className='color-gray mb-5' style={{fontSize: '0.95em'}}>{moment(this.props.videoInfo.created_at).format('YYYY년 MM월 DD일 HH:mm')}</p>

          {/*<Button basic className='mb-2'>*/}
          {/*  <Icon name='heart empty'/>*/}
          {/*  {this.props.videoInfo.like_num}*/}
          {/*</Button>*/}
          {/*<Button basic className='mb-2'>*/}
          {/*  <Icon name='eye'/>*/}
          {/*  {this.props.videoInfo.view_num}*/}
          {/*</Button>*/}
          <Link to={`/video/detail?id=${this.props.videoInfo.uuid}`}>
            <Button basic className='mb-2'>
              <Icon name='edit'/>
              수정
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}