import React, { Component } from 'react'
import {Container, Divider} from "semantic-ui-react";
import UserContext from "../../UserContext";

export default class HomePage extends Component {
  static contextType = UserContext;

  render () {
    const user = this.context;

    return (
      <div>
        <div style={{width: '100vw', height: '40vh', marginTop: '-10px'}}>
          <div className="p-img-container-main" style={{display: 'flex'}}>
            <h1 style={{margin: 'auto'}}>
              <div className="ml-5 mr-5 mb-2 fw-b" style={{ wordBreak: 'keep-all'}}>
                <h1 style={{color: 'white', textShadow: '5px 5px 10px rgba(0,0,0,.1)'}}><span style={{fontSize: '2.5em'}}>SSP Studio</span> 에 오신 것을 환영합니다.</h1>
              </div>
            </h1>
          </div>
        </div>

        <Container>
          <div className='p-card pd-3 mb-7' style={{marginTop: "-4rem"}}>
            <h1>😎 SSP 똑똑하게 이용하기</h1>
            <p>우리 기업의 채널, {user.group.type === 'VENTURE' && 'IR 자료, '}상품 등 다양한 관리를 <b>SSP Studio</b>에서 한번에!</p>
            <Divider/>
            <p>모르는게 있을 땐? 언제든지 포애퍼 SSP 팀(<a>support@poapper.com</a>)으로 연락주세요.</p>
          </div>

          <div className='p-card pd-3 mb-7'>
            <h2>💡 채널 브랜딩</h2>
            <Divider/>
            <p>채널과 기본 정보를 수정할 수 있습니다.</p>
            {/*<h3>브랜딩</h3>*/}
            <p className='color-gray'>설명이 추가될 예정입니다...</p>
            {/*<h3>정보</h3>*/}
            {/*<p className='color-gray'>설명이 추가될 예정입니다...</p>*/}
          </div>

          <div className='p-card pd-3 mb-7'>
            <h2>🎥 홍보 영상</h2>
            <Divider/>
            <p>영상을 업로드하고 관리합니다.</p>
            {/*<h3>브랜딩</h3>*/}
            <p className='color-gray'>설명이 추가될 예정입니다...</p>
            {/*<h3>정보</h3>*/}
            {/*<p className='color-gray'>설명이 추가될 예정입니다...</p>*/}
          </div>

          <div className='p-card pd-3 mb-7'>
            <h2>✏️ 게시물</h2>
            <Divider/>
            <p>커뮤니티 게시물을 작성하고 수정할 수 있습니다.</p>
            {/*<h3>브랜딩</h3>*/}
            <p className='color-gray'>설명이 추가될 예정입니다...</p>
            {/*<h3>정보</h3>*/}
            {/*<p className='color-gray'>설명이 추가될 예정입니다...</p>*/}
          </div>
        </Container>
       </div>
    )
  }
}