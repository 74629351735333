import React, {Component} from 'react'
import {Button, Container, Form, Icon, Input, Loader} from "semantic-ui-react";
import axios from "axios";
import {Editor} from "@toast-ui/react-editor";

import {
  S3DeleteFile,
  S3UploadFile,
  S3UploadImage,
} from '../../utils/s3-file-upload'
import FileUploadButton from "../../component/file_upload_button";
import RoundProfile from "../../utils/profile";
import { process_editor_images } from '../../utils/file_processing'
import UserContext from "../../UserContext";

export default class BrandPage extends Component {
  editorRef = React.createRef();
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      attachments: [],
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/studio/channelInfo`, {withCredentials: true});
      const channelInfo = response.data;
      this.setState({
        uuid: channelInfo.uuid,
        name: channelInfo.name,
        slug: channelInfo.slug,
        profile_url: channelInfo.profile_url,
        background_url: channelInfo.background_url,
        intro: channelInfo.intro,
        description: channelInfo.description ?? '',
        description_original: channelInfo.description ?? '',
        attachments: channelInfo.attachments ?? [], // original attachment, this will be used in POST body
        attachments_edit: channelInfo.attachments ? channelInfo.attachments.slice() : [], // will traverse when submit, and compare with original attachment
        attachments_file: channelInfo.attachments ? await channelInfo.attachments.map(
          () => {
            return null;
          }) : [], // uploaded file will be here
        attachments_name: channelInfo.attachments ? channelInfo.attachments.map(
          (attachment) => {
            const parsed = attachment.split('/');
            return parsed[parsed.length - 1];
          }) : [], // names reveal in the file list
        isLoaded: true
      });
    } catch (err) {
      console.log(err);
      alert('채널 정보를 로딩하는 데에 실패했습니다.');
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }


  handleSubmit = async () => {
    const slugVal = /^[_A-Za-z0-9-]+$/;
    if (!slugVal.test(this.state.slug)) {
      alert('Slug가 올바르지 않습니다.');
      return;
    }
    const editorInstance = this.editorRef.current.getInstance();
    try {
      const uuid = this.state.uuid;

      this.setState({
        'description': await editorInstance.getMarkdown()
      })
      if (this.state.profile_url_file) {
        const { url } = await S3UploadImage(this.state.profile_url_file, "channel_info/profile", uuid);
        this.setState({
          'profile_url': url
        })
      }
      if (this.state.background_url_file) {
        const { url } = await S3UploadImage(this.state.background_url_file, "channel_info/background", uuid);
        this.setState({
          'background_url': url
        })
      }
      // processing attachment files
      for (let idx = 0; idx < this.state.attachments_edit.length; idx++) {
        const attachment = this.state.attachments_edit[idx];
        if (attachment == null) {
          const url = new URL(this.state.attachments[idx]);
          const pathname = url.pathname;
          await S3DeleteFile(pathname.slice(1, pathname.length)); // delete file from S3
          this.state.attachments[idx] = null;
        }

        if (this.state.attachments_file[idx]) {
          const { url } = await S3UploadFile(this.state.attachments_file[idx], `channel_info/attachment/${uuid}`);
          this.state.attachments.push(url); // should save encodedURI due to comma problem of 'simple-array'
        }
        this.setState({
          attachments: this.state.attachments
        });
      }

      // processing description_images
      // await process_editor_images(this.state.description, this.state.description_original);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/studio/channelInfo`, {
          name: this.state.name,
          slug: this.state.slug,
          profile_url: this.state.profile_url,
          background_url: this.state.background_url,
          intro: this.state.intro,
          description: this.state.description,
          attachments: this.state.attachments.filter(
            (elt) => elt != null
          ), // remove null elements
        }, {
          withCredentials: true,
        });
      alert('채널 정보를 수정했습니다!');
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert('채널 정보 수정에 실패했습니다.');
    }
  }

  handleFileUpload = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let file = event.target.files[0];
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + "_file"]: file,
          [event.target.name]: fileReader.result
        });
      }
      fileReader.readAsDataURL(file);
    }
  }


  handleMultiFileUpload = async (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.state.attachments_file.push(file); // this will be used for uploading file to s3
      this.state.attachments_edit.push(file.name); // we will traverse this when submit
      this.state.attachments_name.push(file.name); // will be revealed in the list
      this.setState({
        attachments_file: this.state.attachments_file,
        attachments_edit: this.state.attachments_edit,
        attachments_name: this.state.attachments_name
      });
    }
    // alert("파일을 등록했습니다! '저장하기'를 눌러야 파일이 저장됩니다.")
  }

  render() {
    const user = this.context;

    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='lightbulb' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>채널 브랜딩</h2>
            <p className='color-gray'>채널 프로필과 채널 소개에 들어갈 내용을 작성합니다.</p>
          </div>
        </div>
        {
          this.state.isLoaded ?
            <div>
              <Form>
                <p className='fw-b'>채널명</p>
                <Form.Input
                  name="name"
                  className='mb-7'
                  fluid
                  placeholder='채널 이름을 정해주세요.'
                  value={this.state.name}
                  onChange={this.handleChange}
                />

                <p className='fw-b'>채널 URL</p>
                <Input
                  name="slug"
                  className='mb-7'
                  label={{basic: true, content: 'https://poscossp.com/channel/'}}
                  fluid
                  placeholder="Slug를 설정해주세요. 영어(A~Z, a~z), 숫자(0~9), 언더바(_), 하이픈(-)만 가능합니다."
                  value={this.state.slug}
                  onChange={this.handleChange}
                />

                <p className='fw-b'>프로필 사진</p>
                <RoundProfile src={this.state.profile_url} size={150}/>
                <FileUploadButton
                  className='mt-5 mb-7'
                  label="프로필 수정"
                  accept="image/*"
                  name="profile_url"
                  handleChange={this.handleFileUpload}
                />

                <p className='fw-b'>배너 이미지</p>
                <div className='p-box-6by1'>
                  <div className='p-box-ch'>
                    <img
                      src={this.state.background_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"} alt="banner"
                      style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px'}}/>
                  </div>
                </div>
                <FileUploadButton
                  className='mt-5 mb-7'
                  label="배너 수정"
                  accept="image/*"
                  name="background_url"
                  handleChange={this.handleFileUpload}
                />

                <p className='fw-b'>간단한 소개</p>
                <Form.Input
                  name='intro'
                  fluid
                  placeholder='간단한 소개를 작성해주세요. (200자 이내)'
                  value={this.state.intro}
                  className='mb-7'
                  onChange={this.handleChange}
                />

                <p className='fw-b'>자세한 소개</p>

                <Editor
                  previewStyle="tab"
                  height={'450px'}
                  ref={this.editorRef}
                  initialValue={this.state.description}
                  hideModeSwitch={true}
                  placeholder='자세한 소개를 작성해주세요. 마크다운 문법을 지원하여 이미지나 표 등을 자유롭게 넣을 수 있습니다.'
                  hooks={{
                    addImageBlobHook: async (blob, callback) => {
                      const { url } = await S3UploadFile(blob, `channel_info/description/${this.state.uuid}`);
                      callback(url, blob.name); // callback(src, alt);
                      return false;
                    }
                  }}
                />

                {
                  user.group.type === 'VENTURE' && <>
                    <p className='fw-b mt-7'>IR 자료 첨부</p>
                    <p>최대 3개의 PDF 파일을 첨부할 수 있습니다. <span className='color-main'>본인 그룹과 VC/투자자들만 확인이 가능합니다.</span></p>
                    {
                      this.state.attachments_edit.length ?
                        <div>
                          {
                            this.state.attachments_edit.map((attachment, idx) => {
                              if (!attachment) return null;
                              return (
                                <div key={idx} className='p-card pd-1 mv-5 p-item-vcentered'>
                                  <a className='color-main' href={attachment}
                                     style={{flexGrow: 1}}>{window.decodeURIComponent(this.state.attachments_name[idx])}</a>
                                  <Button size="small" icon="close"
                                          onClick={() => {
                                            this.state.attachments_file[idx] = null;
                                            this.state.attachments_edit[idx] = null;
                                            this.state.attachments_name[idx] = null;
                                            this.setState({
                                              attachments_file: this.state.attachments_file,
                                              attachments_edit: this.state.attachments_edit,
                                              attachments_name: this.state.attachments_name
                                            })
                                          }}
                                  />
                                </div>
                              );
                            })
                          }
                          {
                            (this.state.attachments_edit.filter(
                              (elt) => elt != null
                            ).length <= 2) ?
                              <FileUploadButton
                                label="파일 추가"
                                accept=".pdf"
                                name="attachments"
                                handleChange={this.handleMultiFileUpload}
                              /> :
                              null
                          }
                        </div> :
                        <div>
                          <p className='color-gray'>등록된 첨부파일이 없습니다.</p>
                          <FileUploadButton
                            label="파일 추가"
                            accept=".pdf"
                            name="attachments"
                            handleChange={this.handleMultiFileUpload}
                          />
                        </div>
                    }
                  </>
                }

                <Form.Button type="submit" primary className='mv-7' onClick={this.handleSubmit}>
                  저장하기
                </Form.Button>
              </Form>
            </div>
            : <Loader active inline='centered'/>
        }
      </Container>
    )
  }
}
