import React, {Component} from 'react'
import {Button, Container, Dropdown, Icon, Image, Menu} from 'semantic-ui-react'
import MediaQuery from "react-responsive/src";
import {withRouter} from 'react-router-dom';
import axios from "axios";
import UserContext from "../UserContext";
import RoundProfile from "../utils/profile";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    }
  }

  static contextType = UserContext;

  goto = (loc) => {
    this.props.history.push(loc);
  }

  login = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?redirect=${window.location.href}`;
  }

  logout = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {withCredentials: true});
  }

  render() {
    const user = this.context;

    return (
      <div style={{
        position: 'fixed',
        width: '100%',
        top: 0,
      }} className='p-navbar pv-4 ph-5'>
        <>
          <Menu secondary>
            {/*<MediaQuery maxWidth={768}>*/}
              <Menu.Item onClick={this.props.openSidebar}>
                <Icon name='sidebar' style={{margin: 0}}/>
              </Menu.Item>
            {/*</MediaQuery>*/}

            <Menu.Item className='nav-logo' style={{paddingLeft: 0, paddingRight: 0}}
                       onClick={this.goto.bind(this, '/')}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Image size='tiny' src={'/ssp-logo-blue.svg'}/>
                <p className='ml-5 fw-b'>Studio</p>
              </div>
            </Menu.Item>

            <MediaQuery minWidth={992}>
              <Menu.Item
                position='right'
                onClick={this.goto.bind(this, '/group')}
              >
                <Icon name={'group'} /> 그룹
              </Menu.Item>
              <Menu.Item onClick={this.goto.bind(this, '/channel/branding')}><Icon name={'lightbulb'} /> 채널 브랜딩</Menu.Item>
              {
                user.group.type === 'VENTURE' && <Menu.Item onClick={this.goto.bind(this, '/channel/info')}><Icon name={'info'}/> 기업 정보</Menu.Item>
              }
              {/*<Dropdown item text='채널'>*/}
              {/*  <Dropdown.Menu>*/}
              {/*    <Dropdown.Item onClick={this.goto.bind(this, '/channel/branding')}>브랜딩</Dropdown.Item>*/}
              {/*    <Dropdown.Item onClick={this.goto.bind(this, '/channel/info')}>정보</Dropdown.Item>*/}
              {/*  </Dropdown.Menu>*/}
              {/*</Dropdown>*/}
              {/*<Dropdown item text='IR'>*/}
              {/*  <Dropdown.Menu>*/}
              {/*    <Dropdown.Item onClick={this.goto.bind(this, '/video')}>영상</Dropdown.Item>*/}
              {/*    <Dropdown.Item onClick={this.goto.bind(this, '/community')}>게시물</Dropdown.Item>*/}
              {/*  </Dropdown.Menu>*/}
              {/*</Dropdown>*/}
                  <Menu.Item onClick={this.goto.bind(this, '/video')}><Icon name={'youtube'}/> 홍보 영상</Menu.Item>
                  <Menu.Item onClick={this.goto.bind(this, '/community')}><Icon name={'pencil'}/> 게시물</Menu.Item>
              <Menu.Item
                onClick={this.goto.bind(this, '/market')}
              >
                <Icon name={'cart'}/> 마켓
              </Menu.Item>
            </MediaQuery>
            <Menu.Item position='right'>
              {
                user ?
                  <Dropdown
                    trigger={
                      <span>
                        <RoundProfile size={35} src={user.profile_url}/>
                      </span>
                    }
                    pointing='top right'
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item text='SSP 홈으로' icon='home' onClick={() => window.location.href='https://poscossp.com'} />
                      <Dropdown.Item text='로그아웃' icon='log out' onClick={this.logout} href={"/"}/>
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  <Button basic onClick={this.login}><Icon name='user'/>로그인</Button>
              }
            </Menu.Item>
          </Menu>
        </>
      </div>
    )
  }
}

export default withRouter(Navbar)