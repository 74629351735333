import React, {Component} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {Link} from "react-router-dom";
import moment from "moment";

export default class PostCard extends Component {
  render() {
    return (
      <div className='p-card pd-1 mb-6' style={{maxWidth: '700px'}}>
        <div className='mb-3'>
          {
            this.props.postInfo.content.split('\n').map((str) => {
              return (<div>{str}</div>)
            })
          }
        </div>
        {
          (this.props.postInfo.img_url) &&
            <div className='mb-3' style={{width: '100%', maxWidth: '500px'}}>
              <div className='p-box-1by1'>
                <div className='p-box-ch'>
                  <img style={{objectFit: 'cover', width: '100%', height: '100%', borderRadius: '5px'}}
                       src={this.props.postInfo.img_url}/>
                </div>
              </div>
            </div>
        }
        <p className='color-gray mb-5'>{moment(this.props.postInfo.created_at).format('YYYY년 MM월 DD일 HH:mm')}</p>

        {/*<Button basic className='mb-2'>*/}
        {/*  <Icon name='heart empty'/>*/}
        {/*  {this.props.postInfo.like_num}*/}
        {/*</Button>*/}
        {/*<Button basic className='mb-2'>*/}
        {/*  <Icon name='comment'/>*/}
        {/*  {this.props.postInfo.comments}*/}
        {/*</Button>*/}
        <Link to={`/community/detail?id=${this.props.postInfo.uuid}`}>
          <Button basic className='mb-2'>
            <Icon name='edit'/>
            수정
          </Button>
        </Link>
      </div>
    );
  }
}