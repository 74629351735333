import React, {Component} from 'react'
import {Button, Container, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import PostCard from "../../component/ir/post.card";
import axios from "axios";

export default class PostPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/community/studio/channel`, {withCredentials: true});
      this.setState({
        posts: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Container>
        <div style={{display: 'flex'}} className='mb-7'>
          <Icon size='large' color='grey' name='pencil' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>커뮤니티 게시물</h2>
            <p className='color-gray'>커뮤니티 게시글을 관리합니다.</p>
          </div>
        </div>

        <Link to='/community/create'>
          <Button primary className='mb-7'>새로운 글쓰기</Button>
        </Link>

        <div style={{marginBottom: '2rem'}}>
          {this.state.posts.map((post, idx) =>
            <PostCard postInfo={post}/>
          )}
        </div>
      </Container>
    )
  }
}