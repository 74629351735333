import React, {Component} from 'react'
import RoundProfile from "../../utils/profile";

export default class RoomCard extends Component {
  displayedAt(createdAt) {
    const milliSeconds = new Date() - new Date(createdAt)
    const seconds = milliSeconds / 1000
    if (seconds < 60) return `방금 전`
    const minutes = seconds / 60
    if (minutes < 60) return `${Math.floor(minutes)}분 전`
    const hours = minutes / 60
    if (hours < 24) return `${Math.floor(hours)}시간 전`
    const days = hours / 24
    if (days < 7) return `${Math.floor(days)}일 전`
    const weeks = days / 7
    if (weeks < 5) return `${Math.floor(weeks)}주 전`
    const months = days / 30
    if (months < 12) return `${Math.floor(months)}개월 전`
    const years = days / 365
    return `${Math.floor(years)}년 전`
  }

  openChat = () => {
    window.open(`/chat/${this.props.room.room.uuid}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  render() {
    return (
      <div onClick={this.openChat}>
        <div className='p-card pd-1 mb-5 hover-zoom pointer'>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <RoundProfile size={80} src={this.props.room.room.user.profile_url}/>
            <div className='ml-5' style={{flexGrow: '1'}}>
              <p className='fs-1 fw-b mb-0'
                 style={{color: '#000000DE'}}>{this.props.room.room.user.name} <span
                  className='color-main fw-n'
                  style={{fontSize: '0.8em'}}>{this.props.room.room.user.group &&`(${this.props.room.room.user.group.name})`}</span></p>
              <p className='mb-0' style={{color: '#000000DE'}}>{this.props.room.lastChat.body}</p>
              <p className='color-gray'
                 style={{textAlign: 'right'}}>{this.displayedAt(this.props.room.lastChat.created_at)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}