import React, { Component } from 'react'
import {
  Button,
  Container,
  Form,
  Icon,
  Input,
} from 'semantic-ui-react'
import { Editor } from '@toast-ui/react-editor'
import axios from 'axios'
import { S3UploadFile} from '../../utils/s3-file-upload'
import UserContext from "../../UserContext";
import { process_editor_images } from '../../utils/file_processing'


export default class VideoCreatePage extends Component {
  state = { tags: [], content_original: '' }
  editorRef = React.createRef()
  static contextType = UserContext;

  handleData = (e, data) => {
    let value = data.value
    if(data.name === 'youtube_id') {
      try {
        const url = new URL(value)
        const videoId = url.searchParams.get('v')

        console.log('videoId', videoId)

        if(videoId) {
          value = videoId
        }
      } catch (e){

      }

    }
    this.setState({
      [data.name]: value,
    })

  }

  handleSave = async () => {
    const editorInstance = this.editorRef.current.getInstance()

    this.setState({
      'content': await editorInstance.getMarkdown()
    })

    // processing description_images
    await process_editor_images(this.state.content, this.state.content_original);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/video`, {
        'title': this.state.title,
        'content': this.state.content,
        'youtube_id': this.state.youtube_id,
        'tags': this.state.tags,
        // "attachment": this.state.attachment, // TODO: if you want to add attachment, then you it! CHECK THE STATE NAME!
      }, { withCredentials: true })
      alert('새로운 영상을 업로드 했습니다!')
      window.location.href = '/video'
    } catch (e) {
      alert('새로운 영상 업로드에 실패했습니다.')
    }
  }

  handleFileUpload = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader()
      let file = event.target.files[0]
      fileReader.onloadend = () => {
        this.setState({
          [event.target.name + '_file']: file,
          [event.target.name]: fileReader.result,
        })
      }
      fileReader.readAsDataURL(file)
    }
  }

  render () {
    const user = this.context;

    const defaultTags = []
    const tagsLabel = (tags) => {
      const tag = []
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t,
        })
      }
      return tag.concat(defaultTags)
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <Container>
        <div className='mb-7 is-flex'>
          <Icon size="large" color="grey" name="youtube"
                style={{ marginTop: '1.3rem' }}/>
          <div className="ml-5">
            <h2 className="p-header" style={{ marginBottom: '0.2em' }}>홍보 영상 업로드</h2>
            <p className="color-gray">홍보 영상과 내용을 업로드합니다.</p>
          </div>
        </div>

        <Form>
          <p className="fw-b">영상 제목</p>
          <Form.Field
            control={Input}
            fluid
            name="title"
            onChange={this.handleData}
            placeholder="영상 제목을 입력해주세요."
            className="mb-7"
          />

          <p className="fw-b">YouTube 영상 ID</p>

          <div style={{ maxWidth: '150px' }} className="mb-5">
            <div className="p-box-16by9">
              <img alt="YouTube Thumbnail" className="p-box-ch"
                   style={{ objectFit: 'cover', borderRadius: '5px' }}
                   src={`https://img.youtube.com/vi/${this.state.youtube_id}/0.jpg`}/>
            </div>
          </div>

          <Form.Field
            control={Input}
            fluid
            placeholder="ID를 입력해주세요. (ex. youtube.com/watch?v=[여기가 ID 부분입니다])"
            className="mb-7"
            name="youtube_id"
            value={this.state.youtube_id}
            onChange={this.handleData}
          />

          <p className="fw-b">자세한 설명</p>
          <Editor
            previewStyle="tab"
            height={'450px'}
            ref={this.editorRef}
            initialValue={''}
            hideModeSwitch={true}
            placeholder="자세한 설명을 작성해주세요. 마크다운 문법을 지원하여 이미지나 표 등을 자유롭게 넣을 수 있습니다."
            hooks={{
              addImageBlobHook: async (blob, callback) => {
                const { url } = await S3UploadFile(blob, `video/description/${user.group.uuid}`);
                callback(url, blob.name); // callback(src, alt);
                return false;
              }
            }}
          />

          <p className="fw-b mt-7">검색 태그</p>
          <Form.Dropdown
            placeholder="검색 태그를 등록해주세요."
            fluid
            search
            selection
            multiple
            allowAdditions
            onAddItem={(event, data) => defaultTags.push(
              { key: data.value, text: data.value, value: data.value })}
            options={tagsLabel(this.state.tags)}
            renderLabel={renderLabel}
            value={this.state.tags}
            name="tags"
            onChange={this.handleData}
          />

          {/*<p className="fw-b mt-7">첨부 자료 PDF</p>*/}

          {/*<div className="mt-5 mb-7">*/}
          {/*  <Form.Input accept="file/*" type="file" name="attachment"*/}
          {/*              onChange={this.handleFileUpload}/>*/}
          {/*</div>*/}

          <Button onClick={this.handleSave} primary
                  className="mv-7">업로드</Button>
        </Form>
      </Container>
    )
  }
}