import React, {Component} from 'react'
import {Container, Form, Grid, Icon, Loader} from "semantic-ui-react";
import {DateInput} from "semantic-ui-calendar-react";
import axios from "axios";
import moment from "moment";

export default class InfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      founding_date: ''
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/studio/ventureInfo`, {withCredentials: true});
      const ventureInfo = response.data;
      console.log(ventureInfo);
      this.setState({
        'corporate_name': ventureInfo.corporate_name,
        'ceo_name': ventureInfo.ceo_name,
        'company_address': ventureInfo.company_address,
        'industry': ventureInfo.industry,
        'business_details': ventureInfo.business_details,
        'founding_date': ventureInfo.founding_date,
        'company_type': ventureInfo.company_type,
        'employee_num': ventureInfo.employee_num,
        'revenue': ventureInfo.revenue,
        'website_url': ventureInfo.website_url,
        'isLoaded': true
      })
    } catch (err) {
      alert('벤처 정보를 로딩하는 데에 실패했습니다.');
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleSubmit = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/studio/ventureInfo`, {
        'corporate_name': this.state.corporate_name,
        'ceo_name': this.state.ceo_name,
        'company_address': this.state.company_address,
        'industry': this.state.industry,
        'business_details': this.state.business_details,
        'founding_date': this.state.founding_date,
        'company_type': this.state.company_type,
        'employee_num': this.state.employee_num,
        'revenue': this.state.revenue,
        'website_url': this.state.website_url
      }, {withCredentials: true});
      alert('기업 정보를 수정했습니다.');
    } catch (err) {
      alert('기업 정보 수정에 실패했습니다.');
    }
  }

  render() {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='info' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>채널 정보</h2>
            <p className='color-gray'>벤처 기본 정보를 작성합니다.</p>
          </div>
        </div>

        {this.state.isLoaded ?
          <Form onSubmit={this.handleSubmit}>
            <p className='fw-b mb-7'>회사 개요</p>
            <Grid doubling columns={4} className='mb-7'>
              <Grid.Column>
                <div className='has-text-centered'>
                  <Icon name='stopwatch' size='huge' style={{color: '#005386'}}/>
                  <p className='mt-4 mb-2 fw-b'>설립일</p>
                  <DateInput
                    name='founding_date'
                    placeholder='설립일을 선택해주세요.'
                    startMode='year'
                    dateFormat={"YYYY-MM-DD"}
                    value={moment(this.state.founding_date).format('YYYY-MM-DD')}
                    onKeyDown={e => e.preventDefault()}
                    onChange={this.handleChange}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='has-text-centered'>
                  <Icon name='building' size='huge' style={{color: '#005386'}}/>
                  <p className='mt-4 mb-2 fw-b'>기업형태</p>
                  <Form.Input
                    name='company_type'
                    placeholder='기업형태를 입력해주세요.'
                    value={this.state.company_type}
                    onChange={this.handleChange}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='has-text-centered'>
                  <Icon name='users' size='huge' style={{color: '#005386'}}/>
                  <p className='mt-4 mb-2 fw-b'>사원수</p>
                  <Form.Input
                    type='number'
                    name='employee_num'
                    fluid
                    placeholder='사원수를 입력해주세요.'
                    value={this.state.employee_num}
                    onChange={this.handleChange}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='has-text-centered'>
                  <Icon name='database' size='huge' style={{color: '#005386'}}/>
                  <p className='mt-4 mb-2 fw-b'>매출액</p>
                  <Form.Input
                    type='number'
                    name='revenue'
                    fluid
                    placeholder='매출액을 입력해주세요. (단위: 백만원)'
                    value={this.state.revenue}
                    onChange={this.handleChange}
                  />
                </div>
              </Grid.Column>
            </Grid>

            <Form.Input
              name='corporate_name'
              label='법인명(단체명)'
              fluid
              placeholder='법인명(단체명)을 입력해주세요.'
              value={this.state.corporate_name}
              onChange={this.handleChange}
            />

            <Form.Input
              name='industry'
              label='업종'
              fluid
              placeholder='업종을 입력해주세요.'
              value={this.state.industry}
              onChange={this.handleChange}
            />


            <Form.Input
              name='ceo_name'
              label='대표자명'
              fluid
              placeholder='대표자명을 입력해주세요.'
              value={this.state.ceo_name}
              onChange={this.handleChange}
            />

            <Form.Input
              name='website_url'
              label='홈페이지'
              fluid
              placeholder='홈페이지 URL을 입력해주세요.'
              value={this.state.website_url}
              onChange={this.handleChange}
            />

            <Form.Input
              name='company_address'
              label='기업주소'
              fluid
              placeholder='회사가 위치한 주소을 입력해주세요.'
              value={this.state.company_address}
              onChange={this.handleChange}
            />

            <Form.Input
              name='business_details'
              label='사업내용'
              fluid
              placeholder='사업 내용을 간단히 입력해주세요. (200자 이내)'
              value={this.state.business_details}
              onChange={this.handleChange}
            />

            <Form.Button type='submit' primary className='mv-7'>저장하기</Form.Button>
          </Form>
          : <Loader active inline='centered'/>}
      </Container>
    )
  }
}