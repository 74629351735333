import AWS from 'aws-sdk'

/**
 * File upload functions
 * - Put
 * - Delete
 * @author: HSY
 */

// UPLOAD RULE
// s3 upload - don't need encoding
// db save - need encoded due to comma
// so, you might use `decodeURI` to delete file using saved url in the db

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME
const REGION = process.env.REACT_APP_S3_REGION
const DIST_URL = process.env.REACT_APP_S3_CF_DIST_URL

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

export const S3UploadImage = async (file, directory, uuid) => {
  const fileRefined = directory + "/" + uuid;

  const params = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: fileRefined,
  };

  try {
    await myBucket.putObject(params).promise();

    return {
      file: fileRefined,
      url: DIST_URL + "/" + fileRefined
    }
  } catch (err) {
    console.log(err)
  }
}


// If you want to hashing-like feature, then use its uuid into *directory* parameter.
export const S3UploadFile = async (file, directory) => {
  const fileRefined = directory + "/" + file.name;

  const params = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: fileRefined,
  };

  try {
    await myBucket.putObject(params).promise();

    return {
      file: file.name,
      url: DIST_URL + "/" + directory + "/" + window.encodeURIComponent(file.name)
    }
  } catch (err) {
    console.log(err)
  }
}

export const S3DeleteFile = async (filePath) => {

  const params = {
    Bucket: S3_BUCKET,
    Key: window.decodeURIComponent(filePath), // decode URI into Korean
  };

  try {
    await myBucket.deleteObject(params).promise();
  } catch (err) {
    console.log(err)
  }
}