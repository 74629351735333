import React, {Component} from "react";
import moment from "moment";
import RoundProfile from "../../utils/profile";

export default class ChatBox extends Component {
  render() {
    return (
      <div>
        {
          this.props.isMyChat ? <div style={{display: 'flex', justifyContent: 'flex-end'}} className='mt-2 mb-2'>
              <div style={{display: "flex", maxWidth: '250px', alignItems: 'flex-end'}}>
                <p style={{flexShrink: 0}}
                   className='color-gray fs-m1 mr-4'>{moment(this.props.chat.created_at).format('MM.DD. HH:mm')}</p>
                <div className='p-card pv-4 ph-5' style={{borderRadius: '20px', background: '#00a4e5'}}>
                  <p className='mb-0 fw-b color-white'>{this.props.chat.sender.name}</p>
                  <p className='color-white'>{this.props.chat.body}</p>
                </div>
              </div>
            </div>
            : <div style={{display: 'flex'}} className='mt-2 mb-2'>
              <RoundProfile size={40} src={this.props.chat.sender.profile_url}/>
              <div style={{display: "flex", maxWidth: '250px', alignItems: 'flex-end'}}>
                <div className='p-card ml-5 pv-4 ph-5' style={{borderRadius: '20px'}}>
                  <p className='mb-0 fw-b'>{this.props.chat.sender.name}</p>
                  <p>{this.props.chat.body}</p>
                </div>
                <p style={{flexShrink: 0}}
                   className='color-gray fs-m1 ml-4'>{moment(this.props.chat.created_at).format('MM.DD. HH:mm')}</p>
              </div>
            </div>
        }
      </div>
    )
  }
}