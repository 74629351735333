
// processing description_images
import { S3DeleteFile } from './s3-file-upload'

function extractAllImageUrls (text) {
  const match_arr = [...text.matchAll(/!\[.*?\]\((.*?)\)/g)];
  return match_arr.map((match_ret) => {return match_ret[1]});
}

export async function process_editor_images (current_content, original_content) {
  const img_urls = extractAllImageUrls(original_content);

  for (let idx = 0; idx < img_urls.length; idx++) {
    const img_url = img_urls[idx];
    const isS3URL = img_url.match(process.env.REACT_APP_S3_CF_DIST_URL);
    if (!isS3URL) continue; // not image from S3, not delete!
    const isExist = current_content.match(img_url);
    if (isExist) continue; // image is used in the content, not delete!

    const url = new URL(img_url);
    const pathname = url.pathname;
    const fileKey = pathname.slice(1, pathname.length);
    await S3DeleteFile(fileKey); // delete not using image
  }
}