import React, {Component} from 'react'
import {Container, Icon} from "semantic-ui-react";
import axios from "axios";
import RoomCard from "../../component/chat/room.card";

export default class ChatListPage extends Component {
  state = {rooms: []}

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/studio`, {withCredentials: true});
      console.log(response.data);
      this.setState({
        rooms: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='chat' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>채팅방 목록</h2>
            <p className='color-gray'>SSP 사용자들과의 대화 목록입니다.</p>
          </div>
        </div>
        <div>
          {this.state.rooms.map((room, idx) =>
            <RoomCard room={room}/>
          )}
        </div>
      </Container>
    )
  }
}