import React, {Component} from 'react'
import {Button, Container, Icon} from "semantic-ui-react";
import YoutubeCard from "../../component/ir/youtube.card";
import {Link} from "react-router-dom";
import axios from "axios";

export default class VideoPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videos: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/video/studio/channel`, {withCredentials: true});
      this.setState({
        videos: response.data
      })
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='youtube' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>홍보 영상</h2>
            <p className='color-gray'>홍보 영상과 내용을 관리합니다.</p>
          </div>
        </div>

        <Link to='/video/create'>
          <Button primary className='mb-7'>새로운 영상 업로드</Button>
        </Link>

        <div className='auto-grid' style={{padding: '0'}}>
          {this.state.videos.map((video, idx) =>
            <YoutubeCard videoInfo={video}/>
          )}
        </div>

      </Container>
    )
  }
}