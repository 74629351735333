import React, {Component} from 'react'
import {Checkbox, Container, Divider, Form, Icon, Label, Loader, Popup, Table} from "semantic-ui-react";
import axios from "axios";
import RoundProfile from "../../utils/profile";

export default class GroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      keywords: [],
      tags: []
    }
  }

  async componentDidMount() {
    try {
      const keywords = (await axios.get(`${process.env.REACT_APP_API_URL}/keyword`, {withCredentials: true})).data;
      const fieldOptions = [];
      for (const k of keywords) {
        for (const t of k.tags) {
          fieldOptions.push({
            key: t.id,
            text: t.name,
            value: t.id
          })
        }
      }

      console.log(fieldOptions);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/studio/group`, {withCredentials: true});
      const group = response.data;

      console.log(group);

      this.setState({
        uuid: group.uuid,
        name: group.name,
        profile: group.profile_url,
        subtype: group.subtype,
        tags: group.tags && group.tags.map((tag) => tag.id),
        fieldOptions: fieldOptions,
        users: group.users,
        isLoaded: true,
        keywords: keywords,
      })
    } catch (err) {
      alert('그룹 정보를 로딩하는 데에 실패했습니다.');
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    })
  }

  handleSubmit = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/studio/group`, {
          tags: this.state.tags
        }, {
          withCredentials: true,
        });
      alert('그룹 정보를 수정했습니다!');
      window.location.reload();
    } catch (err) {
      alert('그룹 정보 수정에 실패했습니다.');
    }
  }

  render() {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='group' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>그룹 정보</h2>
            <p className='color-gray'>그룹의 활동 분야를 설정해보세요.</p>
          </div>
        </div>

        {
          this.state.isLoaded ?
            <div>
              <Form>
                <div className='p-card pd-2 mb-7'>
                  <div className='is-flex' style={{alignItems: 'center', flexDirection: 'column'}}>
                    <RoundProfile src={this.state.profile} size={80}/>
                    <p className='fw-b fs-1 mt-5'>{this.state.name}</p>
                    <div>
                      {this.state.subtype.map((type, idx) =>
                        <Popup content={type.description} position='bottom center'
                               trigger={<Label key={idx} className='mb-1'>{type.name}</Label>}/>
                      )}
                    </div>
                  </div>
                </div>

                <div className='p-card pd-2 mb-7'>

                  {/*<p className='fw-b'>활동 분야</p>*/}
                  {/*<Form.Dropdown*/}
                  {/*  placeholder='활동 분야 키워드를 선택해주세요.'*/}
                  {/*  fluid*/}
                  {/*  multiple*/}
                  {/*  search*/}
                  {/*  selection*/}
                  {/*  options={this.state.fieldOptions}*/}
                  {/*  name='tags'*/}
                  {/*  onChange={this.handleChange}*/}
                  {/*  value={this.state.field}*/}
                  {/*/>*/}

                  <p className='fw-b'>활동 분야</p>
                  <Form.Dropdown
                    placeholder='활동 분야를 선택해주세요.'
                    fluid
                    multiple
                    search
                    selection
                    options={this.state.fieldOptions}
                    name='tags'
                    onChange={this.handleChange}
                    value={this.state.tags}
                  />

                  <p className='fw-b fs-1'>활동 분야 리스트</p>
                  {
                    this.state.keywords.map((keyword) => <div>
                      <p className='fw-b mt-5 mb-3'>{keyword.name}</p>
                      <div>
                      {
                        keyword.tags.map((tag) =>
                          <Label className='mb-2'>{tag.name}</Label>
                        )
                      }
                      </div>
                    </div>)
                  }
                  <p className='mt-7'>새로운 분야를 추가하고 싶다면? <b className='color-main'>poscossp@poapper.com</b>으로 알려주세요!</p>

                  <Form.Button type="submit" primary className='mt-7' onClick={this.handleSubmit}>
                    저장하기
                  </Form.Button>
                </div>

                {/*<p className='fw-b'>프로필 사진</p>*/}
                {/*<img*/}
                {/*  src={this.state.profile ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}*/}
                {/*  style={{*/}
                {/*    width: '150px',*/}
                {/*    height: '150px',*/}
                {/*    objectFit: 'cover',*/}
                {/*    borderRadius: '75px',*/}
                {/*    border: '1px solid #eaeaea'*/}
                {/*  }}/>*/}
                {/*<FileUpload*/}
                {/*  className='mt-5 mb-7'*/}
                {/*  label="프로필 수정"*/}
                {/*  accept="image/*"*/}
                {/*  name="profile"*/}
                {/*  handleChange={this.handleFileUpload}*/}
                {/*/>*/}

                {/*<p className='fw-b'>활동 분야</p>*/}
                {/*<Form.Dropdown*/}
                {/*  placeholder='활동 분야 키워드를 선택해주세요.'*/}
                {/*  fluid*/}
                {/*  multiple*/}
                {/*  search*/}
                {/*  selection*/}
                {/*  options={this.state.fieldOptions}*/}
                {/*  name='field'*/}
                {/*  onChange={this.handleChange}*/}
                {/*  value={this.state.field}*/}
                {/*/>*/}


              </Form>

              {/*<p className='fw-b fs-1'>그룹 맴버들</p>*/}

              {/*<Table celled selectable>*/}
              {/*  <Table.Header>*/}
              {/*    <Table.Row>*/}
              {/*      <Table.HeaderCell width={1}>#</Table.HeaderCell>*/}
              {/*      <Table.HeaderCell width={4}>이름</Table.HeaderCell>*/}
              {/*      <Table.HeaderCell width={4}>이메일</Table.HeaderCell>*/}
              {/*      <Table.HeaderCell width={3}>가입일</Table.HeaderCell>*/}
              {/*    </Table.Row>*/}
              {/*  </Table.Header>*/}
              {/*  <Table.Body>*/}
              {/*    {this.state.users ?*/}
              {/*      this.state.users.map((user, idx) =>*/}
              {/*        <Table.Row key={idx}>*/}
              {/*          <Table.Cell>{idx + 1}</Table.Cell>*/}
              {/*          <Table.Cell>*/}
              {/*            <div style={{display: 'flex', alignItems: 'center'}}>*/}
              {/*              <RoundProfile src={user.profile_url} size={35}/>*/}
              {/*              <span className='fw-b mh-3'>{user.name}</span>*/}
              {/*              <Label>{user.user_type === 'ADMIN' ? '관리자' : '맴버'}</Label>*/}
              {/*            </div>*/}
              {/*          </Table.Cell>*/}
              {/*          <Table.Cell>*/}
              {/*            {user.email}*/}
              {/*          </Table.Cell>*/}
              {/*          <Table.Cell>*/}
              {/*            {moment(user.created_at).format('YYYY년 MM월 DD일 HH:mm')}*/}
              {/*          </Table.Cell>*/}
              {/*        </Table.Row>*/}
              {/*      )*/}

              {/*      : null}*/}

              {/*  </Table.Body>*/}
              {/*</Table>*/}
            </div>
            : <Loader active inline='centered'/>
        }
      </Container>
    )
  }

}

