import React, {Component} from 'react'
import axios from "axios";
import {Button, Container, Form, Icon, Input, Loader} from "semantic-ui-react";
import {Editor} from "@toast-ui/react-editor";
import { S3UploadFile } from "../../utils/s3-file-upload";
import { process_editor_images } from '../../utils/file_processing'

import UserContext from "../../UserContext";


export default class VideoEditPage extends Component {
  state = {tags: [], isLoading: true}
  editorRef = React.createRef();
  static contextType = UserContext;

  constructor(props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
  }

  handleData = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    try {
      const res = (await axios.get(`${process.env.REACT_APP_API_URL}/video/${this.query.get('id')}`, {withCredentials: true})).data;

      const oldTags = [];
      for (const tag of res.tags) {
        oldTags.push(tag.name);
      }

      this.setState({
        isLoading: false,
        title: res.title,
        content: res.content,
        content_original: res.content,
        youtube_id: res.youtube_id,
        tags: oldTags,
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleUpdate = async () => {
    const editorInstance = this.editorRef.current.getInstance()

    this.setState({
      'content': await editorInstance.getMarkdown()
    })

    // processing description_images
    await process_editor_images(this.state.content, this.state.content_original);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/video/${this.query.get('id')}`, {
        'title': this.state.title,
        'content': this.state.content,
        'youtube_id': this.state.youtube_id,
        'tags': this.state.tags,
      }, {withCredentials: true});
      alert('영상을 수정했습니다!');
      window.location.href = "/video"
    } catch (e) {
      alert("영상 수정에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/video/${this.query.get('id')}`, {withCredentials: true});
      alert('영상을 삭제했습니다!')
      window.location.href = '/video'
    } catch (e) {
      alert('영상 삭제에 실패했습니다.')
    }
  }

  render() {
    const user = this.context;

    const defaultTags = [];
    const tagsLabel = (tags) => {
      const tag = [];
      for (const t of tags) {
        tag.push({
          key: t,
          text: t,
          value: t
        })
      }
      return tag.concat(defaultTags);
    }

    const renderLabel = (label) => ({
      content: `${label.text}`,
    })

    return (
      <Container>
        <div className='mb-7 is-flex'>
          <Icon size='large' color='grey' name='youtube' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>홍보 영상 수정</h2>
            <p className='color-gray'>홍보 영상과 내용을 수정합니다.</p>
          </div>
        </div>
        {
          (!this.state.isLoading) ?
            <Form>
              <p className='fw-b'>영상 제목</p>
              <Form.Field
                control={Input}
                fluid
                name='title'
                onChange={this.handleData}
                placeholder='영상 제목을 입력해주세요.'
                className='mb-7'
                value={this.state.title}
              />

              <p className='fw-b'>YouTube 영상 ID</p>

              <div style={{maxWidth: '150px'}} className='mb-5'>
                <div className='p-box-16by9'>
                  <img alt='YouTube Thumbnail' className='p-box-ch' style={{objectFit: 'cover', borderRadius: '5px'}}
                       src={`https://img.youtube.com/vi/${this.state.youtube_id}/0.jpg`}/>
                </div>
              </div>

              <Form.Field
                control={Input}
                fluid
                placeholder="ID를 입력해주세요. (ex. youtube.com/watch?v=[여기가 ID 부분입니다])"
                className='mb-7'
                name='youtube_id'
                onChange={this.handleData}
                value={this.state.youtube_id}
              />


              <p className='fw-b'>자세한 설명</p>
              <Editor
                previewStyle="tab"
                height={'450px'}
                ref={this.editorRef}
                initialValue={this.state.content}
                hideModeSwitch={true}
                placeholder="자세한 설명을 작성해주세요. 마크다운 문법을 지원하여 이미지나 표 등을 자유롭게 넣을 수 있습니다."
                hooks={{
                  addImageBlobHook: async (blob, callback) => {
                    const { url } = await S3UploadFile(blob, `video/description/${user.group.uuid}`);
                    callback(url, blob.name); // callback(src, alt);
                    return false;
                  }
                }}
              />

              <p className='fw-b mt-7'>검색 태그</p>
              <Form.Dropdown
                placeholder='검색 태그를 등록해주세요.'
                fluid
                search
                selection
                multiple
                allowAdditions
                onAddItem={(event, data) => defaultTags.push({key: data.value, text: data.value, value: data.value})}
                options={tagsLabel(this.state.tags)}
                renderLabel={renderLabel}
                value={this.state.tags}
                name='tags'
                onChange={this.handleData}
              />

              <Button onClick={this.handleUpdate} className='mv-7 mr-5'>수정</Button>
              <Button onClick={this.handleDelete} negative className='mv-7'>삭제</Button>
            </Form>
            : <Loader active inline='centered'/>
        }
      </Container>
    )
  }
}