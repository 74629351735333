import React, {Component} from 'react'
import {Button, Container, Form, Icon, Loader} from "semantic-ui-react";
import axios from "axios";

export default class PostEditPage extends Component {
  state = {isLoading: true}

  constructor(props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
  }

  handleData = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    try {
      const res = (await axios.get(`${process.env.REACT_APP_API_URL}/community/${this.query.get('id')}`)).data;

      this.setState({
        isLoading: false,
        content: res.content,
        preview: res.img_url,
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleUpdate = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/community/${this.query.get('id')}`, {
        'content': this.state.content,
      }, {withCredentials: true});
      alert('게시물을 수정했습니다!');
      window.location.href = "/community"
    } catch (e) {
      alert("게시물 수정에 실패했습니다.");
    }
  }

  handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/community/${this.query.get('id')}`, {withCredentials: true});
      alert('게시물을 삭제했습니다!')
      window.location.href = '/community'
    } catch (e) {
      alert('게시물 삭제에 실패했습니다.')
    }
  }

  render() {
    return (
      <Container>
        <div className='mb-7 is-flex'>
          <Icon size='large' color='grey' name='pencil' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>커뮤니티 게시물 수정</h2>
            <p className='color-gray'>게시글을 수정합니다.</p>
          </div>
        </div>

        {
          !this.state.isLoading ?
            <>
              <Form>
                <p className='fw-b'>내용</p>
                <Form.TextArea
                  row={7}
                  fluid
                  placeholder='내용을 입력해주세요.'
                  className='mb-7'
                  name='content'
                  value={this.state.content}
                  onChange={this.handleData}
                />

                {
                  this.state.preview &&
                  <>
                    <p className='fw-b'>사진</p>
                    <div style={{maxWidth: '500px'}}>
                      <div className='p-box-1by1'>
                        <div className='p-box-ch'>
                          <img
                            src={this.state.preview}
                            style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px'}}/>
                        </div>
                      </div>
                    </div>
                  </>
                }

              </Form>
              <Button onClick={this.handleUpdate} className='mv-7 mr-5'>수정</Button>
              <Button onClick={this.handleDelete} negative className='mv-7'>삭제</Button>
            </>
            : <Loader active inline='centered'/>
        }
      </Container>
    )
  }
}