import React, {Component} from "react";
import axios from "axios";
import ChatBox from "./chat.box";
import UserContext from "../../UserContext";
import RoundProfile from "../../utils/profile";

class ChatRoom extends Component {
  static contextType = UserContext;
  state = {chats: [], isLoading: true, newMsg: ''}

  handleChange = (event) => {
    this.setState({
      newMsg: event.target.value
    });
  }

  async componentDidMount() {

    try {
      // Context 유저로 바꾸기
      const user = await axios(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {withCredentials: true});

      this.setState({
        isLoading: false,
        userId: user.data.uuid,
        user: user.data,
        group: user.data.group,
        roomId: this.props.roomId
      })

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/history/${this.state.roomId}`, {withCredentials: true});
      console.log(res.data);
      this.setState({
        chats: res.data
      })

      this.props.socket.on(this.state.roomId, (msg) => {
        console.log(msg);
        this.setState({
          chats: this.state.chats.concat(msg)
        });
      })
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  }

  send = () => {
    if (this.state.newMsg === '') return;

    this.props.socket.emit('send', {
      sender: this.state.user,
      room_id: this.state.roomId,
      body: this.state.newMsg
    });

    this.setState({
      newMsg: ''
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({behavior: "smooth"});
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.send();
    }
  }

  render() {
    const user = this.context;

    return (
      <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div style={{background: '#fff', borderBottom: '1px #eaeaea solid', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className='pd-1'>
          <h1 className='mb-0'>채팅</h1>
          {
            !this.state.isLoading && <RoundProfile size={60} src={this.state.group.profile_url}/>
          }
        </div>

        <div id='chat' style={{flex: 1, overflowY: 'scroll', padding: '0 1rem'}}>
          {
            this.state.chats.map((chat, idx) =>
              <div key={idx}>
                <ChatBox chat={chat} isMyChat={this.state.userId === chat.sender.uuid}/>
              </div>
            )
          }
          <div style={{float: "left", clear: "both"}}
               ref={(el) => {
                 this.messagesEnd = el;
               }}>
          </div>
        </div>

        {
          user ? <div style={{background: '#fff', borderTop: '1px #eaeaea solid', display: 'flex'}} className='pd-1'>
              <input
                onKeyPress={this.onKeyPress}
                style={{border: 'none', flexGrow: '1'}}
                className='pr-5 no-border'
                placeholder='여기에 메시지를 입력해주세요.'
                onChange={this.handleChange}
                value={this.state.newMsg}
              />
              <div className='p-button fw-b' onClick={this.send}>전송</div>
            </div>
            : <p className='color-gray pd-1 has-text-centered'
                 style={{background: '#fff', borderTop: '1px #eaeaea solid'}}>로그인 후 이용 가능</p>
        }
      </div>
    )
  }
}

export default ChatRoom;