import React, {Component} from 'react'
import {Button, Container, Form, Icon} from "semantic-ui-react";
import axios from "axios";
import { S3UploadFile } from '../../utils/s3-file-upload'
import FileUploadButton from "../../component/file_upload_button";

export default class PostCreatePage extends Component {
  state = {preview: ''}

  handleData = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSave = async () => {
    try {
      // TODO: check this works!
      if (this.state.post_image) {
        const {file, url} = await S3UploadFile(this.state.post_image, "community/post_image");
        this.setState({
          "post_image": url
        })
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/community`, {
        "content": this.state.content,
        "img_url": this.state.post_image
      }, { withCredentials: true });

      alert('새로운 게시물을 업로드 했습니다!');
      window.location.href = "/community"
    } catch (e) {
      alert("새로운 게시물 업로드에 실패했습니다.");
    }
  }

  handleImageSelect = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        [e.target.name]: file,
        preview: reader.result
      })
    }
    reader.readAsDataURL(file);
  }

  render() {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='pencil' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>커뮤니티 게시물 업로드</h2>
            <p className='color-gray'>게시글을 작성합니다.</p>
          </div>
        </div>

        <Form>
          <p className='fw-b'>내용</p>
          <Form.TextArea
            row={7}
            fluid
            placeholder='내용을 입력해주세요.'
            className='mb-7'
            name='content'
            onChange={this.handleData}
          />

          <p className='fw-b'>사진</p>
          <div style={{maxWidth: '500px'}}>
            <div className='p-box-1by1'>
              <div className='p-box-ch'>
                <img
                  src={this.state.preview === '' ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.preview}
                  style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px'}}/>
              </div>
            </div>
          </div>
          <div className='mt-5 mb-7'>
            <FileUploadButton
              className='mt-5 mb-7'
              label="사진 올리기"
              accept="image/*"
              name="post_image"
              handleChange={this.handleImageSelect}
            />
            {/*<Form.Input type='file' name='post_image' onChange={this.handleImageSelect}/>*/}
          </div>
        </Form>
        <Button onClick={this.handleSave} primary className='mv-7'>게시글 작성 완료</Button>
      </Container>
    )
  }
}