import React, {Component} from 'react'
import {Route, Switch, withRouter} from "react-router-dom";
import {Button, Divider, Icon, Menu, Sidebar} from "semantic-ui-react";
import axios from "axios";
import Navbar from "./component/navbar";
import GroupPage from "./pages/group/group.page";
import InfoPage from "./pages/channel/info.page";
import BrandPage from "./pages/channel/brand.page";
import VideoPage from "./pages/video/video.page";
import VideoCreatePage from "./pages/video/video.create.page";
import VideoEditPage from "./pages/video/video.edit.page";
import PostPage from "./pages/community/post.page";
import PostCreatePage from "./pages/community/post.create.page";
import PostEditPage from "./pages/community/post.edit.page";
import Footer from "./component/footer";
import MarketPage from "./pages/market/market.page";
import ItemCreatePage from "./pages/market/item.create.page";
import ItemEditPage from "./pages/market/item.edit.page";
import HomePage from "./pages/home/home.page";
import ChatListPage from "./pages/chat/chat.list.page";
import Chat from "./component/chat/chat";

import UserContext from "./UserContext";
import './App.css';


class App extends Component {
  state = {visible: false, channel: ''};

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {withCredentials: true});
      const user = res.data;

      const hasChannel = await axios.get(`${process.env.REACT_APP_API_URL}/channel/group/${user.group.uuid}`);

      if (hasChannel.data === '') {
        alert("권한이 없습니다.");
        window.location.href = 'https://poscossp.com';
      }

      this.setState({
        user: user,
        channel: hasChannel
      });
    } catch (err) {
      // window.location.href = 'https://api.poscossp.com/v1/auth/login?redirect=' + window.location.href;
    }
  }

  login = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?redirect=${window.location.href}`;
  }

  open = () => {
    this.setState({visible: true})
  }

  goto = (loc) => {
    this.props.history.push(loc);
    this.setState({visible: false});
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {
          this.state.user && this.state.channel !== '' ?
            <div style={{height: 'inherit'}}>
              <Switch>
                <Route exact path='/chat/:id' component={Chat}/>
                <Route exact path='*'>
                  <Navbar openSidebar={this.open}/>
                  <Sidebar
                    animation='push'
                    onHide={() => this.setState({visible: false})}
                    vertical
                    visible={this.state.visible}
                    style={{justifyContent: 'center', display: 'flex', height: '100%', background: '#fff', zIndex: 5}}
                  >
                    <Menu secondary vertical className='pv-5' style={{marginTop: '81px'}}>
                      <Menu.Item className='mb-5 mt-5'
                        icon='group'
                        name='그룹'
                        onClick={this.goto.bind(this, '/group')}
                      />
                      {/*<Divider/>*/}
                      <Menu.Item className='mb-5'
                        icon='lightbulb'
                        name='채널 브랜딩'
                        onClick={this.goto.bind(this, '/channel/branding')}
                      />
                      {
                        this.state.user.group.type === 'VENTURE' &&
                        <Menu.Item className='mb-5'
                          icon='info'
                          name='기업 정보'
                          onClick={this.goto.bind(this, '/channel/info')}
                        />
                      }
                      {/*<Divider/>*/}
                      <Menu.Item className='mb-5'
                        icon='youtube'
                        name='홍보 영상'
                        onClick={this.goto.bind(this, '/video')}
                      />
                      <Menu.Item className='mb-5'
                        icon='pencil'
                        name='게시물'
                        onClick={this.goto.bind(this, '/community')}
                      />
                      {/*<Divider/>*/}
                      <Menu.Item className='mb-5'
                        icon='cart'
                        name='마켓'
                        onClick={this.goto.bind(this, '/market')}
                      />
                      {/*<Divider/>*/}
                      <div className='pb-5'/>
                    </Menu>
                  </Sidebar>
                  <Sidebar.Pushable>
                    <Sidebar.Pusher dimmed={this.state.visible}>
                      <div style={{marginTop: '86px'}}>
                        <Route>
                          <Switch>
                            <Route exact path="/" component={HomePage}/>
                            <Route exact path="/channel/branding" component={BrandPage}/>
                            <Route exact path="/channel/info" component={InfoPage}/>
                            <Route exact path="/video" component={VideoPage}/>
                            <Route exact path="/video/create" component={VideoCreatePage}/>
                            <Route exact path="/video/detail" component={VideoEditPage}/>
                            <Route exact path="/community" component={PostPage}/>
                            <Route exact path="/community/create" component={PostCreatePage}/>
                            <Route exact path="/community/detail" component={PostEditPage}/>
                            <Route exact path="/market" component={MarketPage}/>
                            <Route exact path="/market/item" component={ItemCreatePage}/>
                            <Route exact path="/market/item/edit" component={ItemEditPage}/>
                            <Route exact path="/chat" component={ChatListPage}/>
                            <Route exact path="/group" component={GroupPage}/>
                          </Switch>
                        </Route>
                        <Footer/>
                      </div>
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </Route>
              </Switch>
            </div>
            : <div className='pd-1'>
              <p>로그인이 필요합니다!</p>
              <Button
                style={{boarder: "none"}}
                onClick={this.login}
              >
                <Icon name='sign in'/> 로그인
              </Button>
            </div>
        }
      </UserContext.Provider>
    );
  }
}

export default withRouter(App)