import React, { Component } from 'react'
import {Button, Container, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import MarketItemCard from "../../component/market/market.item.card";
import axios from 'axios';

export default class MarketPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      marketItems: []
    }
  }
  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/market/item/studio/item`, {withCredentials: true});
      this.setState({
        marketItems: response.data
      },)
    } catch(e) {
      console.log(e);
    }
  }

  render () {
    return (
      <Container>
        <div className='is-flex mb-7'>
          <Icon size='large' color='grey' name='cart' style={{marginTop: '1.3rem'}}/>
          <div className='ml-5'>
            <h2 className='p-header' style={{marginBottom: '0.2em'}}>마켓</h2>
            <p className='color-gray'>내 판매 상품을 관리합니다.</p>
          </div>
        </div>

        <Link to='/market/item'>
          <Button primary className='mb-7'>새로운 상품 올리기</Button>
        </Link>

        <div className='auto-grid' style={{padding: '0'}}>
          {this.state.marketItems.map((item, idx) =>
            <Link to={`/market/item/edit/?id=${item.uuid}`}>
              <MarketItemCard itemInfo={item}/>
            </Link>
          )}
        </div>
      </Container>
    )
  }
}