import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import ChatRoom from "./chat.room";

const Chat = ({match}) => {
  const [currentSocket, setCurrentSocket] = useState();

  useEffect(() => {
    setCurrentSocket(socketIOClient("https://api.poscossp.com/chat",{path: '/chat/socket.io'}));
  }, []);

  return (
    <div>
      {currentSocket ? (
        <div>
          {console.log(currentSocket)}
          <ChatRoom roomId={match.params.id} socket={currentSocket} />
        </div>
      ) : (
        <p>로딩중</p>
      )}
    </div>  )
};

export default Chat;